import { Typography, Box, IconButton, Snackbar, LinearProgress, Switch, FormGroup, FormControlLabel, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import { Component, createRef, memo, PureComponent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ArrowBack, CreateNewFolderOutlined, Home, Settings, TurnedInNotTwoTone, UploadFile, UploadFileOutlined, Warning } from '@mui/icons-material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import util from '../../util';
import { snackCaller } from '../../App';

import Image from '../../components/Image';

import FolderLogo from "../../svg/folder.svg"
import GenericLogo from "../../svg/generic.svg"
import { FixedSizeGrid } from "react-window";
import FileViewer from '../../components/FileViewer';

class Item extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            decryptedData: null,
            selected: false,
        };
    }

    componentDidMount() {
        this.setState({ selected: this.props.selected });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selected !== this.props.selected) {
            this.setState({ selected: this.props.selected });
        }
    }

    render() {
        const {
            data,
            setContextMenu,
            setSelectedItem,
            refreshFileReferences,
            dragStart,
            handleItemClick,
        } = this.props;

        const { decryptedData, selected } = this.state;

        return (
            <Button
                id={data._id}
                sx={{
                    height: 210,
                    width: 140,
                    backgroundColor: selected ? '#303030' : '#151515',
                    cursor: 'pointer',
                    flexDirection: 'column',
                }}
                onContextMenu={(event) => {
                    event.preventDefault();
                    setContextMenu({
                        mouseX: event.clientX - 2,
                        mouseY: event.clientY - 4,
                    });
                    setSelectedItem(data);
                }}
                onDragOver={(event) => {
                    event.preventDefault();
                }}
                onDragEnter={(event) => {
                    event.preventDefault();
                    this.setState({ selected: true });
                }}
                onDragLeave={(event) => {
                    event.preventDefault();
                    this.setState({ selected: false });
                }}
                onDrop={(event) => {
                    dragStart(false);
                    if (data.type !== 'folder') return;
                    event.preventDefault();
                    if (event.dataTransfer.getData('text/plain') === data._id) {
                        return console.log("Can't drop on itself");
                    }
                    this.setState({ selected: false });

                    util
                        .postRequest('/api/content/move', {
                            id: event.dataTransfer.getData('text/plain'),
                            parent: data._id,
                        })
                        .then(() => {
                            refreshFileReferences();
                        });
                }}
                draggable={true}
                onDragStart={(event) => {
                    event.dataTransfer.setData('text/plain', data._id);
                    event.dataTransfer.effectAllowed = 'move';
                    dragStart(true);
                }}
                onDragEnd={(event) => {
                    dragStart(false);
                }}
                onClick={(ev) => {
                    handleItemClick(ev, data, decryptedData);
                }}
            >
                <Box
                    sx={{
                        height: 100,
                        width: 100,
                        margin: 'auto',
                        marginTop: 2,
                        position: 'relative',
                        pointerEvents: 'none',
                    }}
                >
                    {data.type !== 'folder' ? (
                        ['png', 'jpg', 'jpeg', 'webp', 'gif'].some((v) =>
                            data.filename.includes(v)
                        ) ? (
                            <Image
                                src={data.thumbnail}
                                setDecryptedData={(data) => this.setState({ decryptedData: data })}
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'cover',
                                    borderRadius: 6,
                                    zIndex: 0.1,
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                }}
                            />
                        ) : (
                            <div>
                                <img
                                    src={GenericLogo}
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'cover',
                                        zIndex: 0.1,
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                    }}
                                />
                                <Typography
                                    sx={{
                                        position: 'absolute',
                                        bottom: 20,
                                        width: '100%',
                                        color: '#fff',
                                        padding: 1,
                                        textAlign: 'center',
                                        fontFamily: 'Russo One',
                                        fontWeight: 100,
                                        fontSize: 20,
                                    }}
                                >
                                    {data.filename.split('.').length === 1
                                        ? '??'
                                        : data.filename.split('.').pop().slice(0, 3)}
                                </Typography>
                            </div>
                        )
                    ) : data.type === 'folder' ? (
                        <div>
                            {data.childPreview.url && (
                                <img
                                    src={data.childPreview.url}
                                    alt="content"
                                    style={{
                                        height: '60%',
                                        width: '60%',
                                        objectFit: 'cover',
                                        position: 'absolute',
                                        top: 9,
                                        left: 32,
                                        borderRadius: 10,
                                    }}
                                />
                            )}
                            <img
                                src={FolderLogo}
                                alt="Item"
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'cover',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    position: 'absolute',
                                }}
                            />
                        </div>
                    ) : (
                        <div>
                            <p>unknown filetype</p>
                        </div>
                    )}
                </Box>

                <Typography
                    sx={{
                        textAlign: 'center',
                        margin: 'auto',
                        wordBreak: 'break-word',
                        color: '#fff',
                        textTransform: 'none',
                    }}
                >
                    {data.filename.split('$Ğ!Ğ$')[0].length > 30
                        ? data.filename.split('$Ğ!Ğ$')[0].substring(0, 30) + '...'
                        : data.filename.split('$Ğ!Ğ$')[0]}
                </Typography>
            </Button>
        );
    }
}

const Grid = ({ fileData, columnCount, itemWidth, gridWidth, gridHeight, refreshFileReferences, dragStart, handleItemClick, selectedItems, setContextMenu, setSelectedItem }) => {
    const gridRef = useRef(null);

    const renderItem = ({ index, style }) => {
        const file = fileData[index];

        if (!file) return null;

        return (
            <div key={file._id} style={style}>
                <Item
                    data={file}
                    refreshFileReferences={refreshFileReferences}
                    dragStart={dragStart}
                    handleItemClick={handleItemClick}
                    selected={selectedItems?.some(item => item._id === file._id)}
                    setContextMenu={setContextMenu}
                    setSelectedItem={setSelectedItem}
                />
            </div>
        );
    };

    return (
        <FixedSizeGrid
            columnCount={columnCount}
            rowCount={Math.ceil(fileData.length / columnCount)}
            columnWidth={itemWidth}
            rowHeight={210}
            width={gridWidth}
            height={gridHeight}
            ref={gridRef}
            style={{
                overflowX: 'hidden',
                overflowY: 'auto',
            }}
            itemData={fileData}
            itemRenderer={renderItem}
        />
    );
};


class GridView extends Component {
    constructor(props) {
        super(props)

        this.state = { length: props.fileData() }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true
        if (this.props.selectedItems?.length !== nextProps.selectedItems?.length) return true
        if ((this.props.selectedItems?.length > 0 && nextProps.selectedItems?.length > 0) && (this.props.selectedItems[0]._id !== nextProps.selectedItems[0]._id)) return true

        if (nextProps.fileData().length !== this.state.length) {
            this.state.length = nextProps.fileData().length

            return true
        }

        return false
    }

    render() {
        let {
            columnCount,
            itemWidth,
            gridWidth,
            gridHeight,
            gridRef,
            refreshFileReferences,
            dragStart,
            handleItemClick,
            selectedItems,
            setContextMenu,
            setSelectedItem,
            fileData
        } = this.props

        return (
            <FixedSizeGrid
                columnCount={columnCount}
                rowCount={Math.ceil(fileData().length / columnCount)}
                columnWidth={itemWidth}
                rowHeight={210}
                width={gridWidth}
                height={gridHeight}
                ref={gridRef}
                style={{
                    overflowX: "hidden",
                    overflowY: "auto",

                }}
            >
                {({ columnIndex, rowIndex, style }) => {
                    const index = rowIndex * columnCount + columnIndex;
                    const file = fileData()[index];

                    if (!file) return null;

                    return (
                        <div key={file._id} style={style}>
                            <Item
                                data={file} refreshFileReferences={refreshFileReferences} dragStart={dragStart} handleItemClick={handleItemClick} selected={selectedItems?.some(item => item._id === file._id)} setContextMenu={setContextMenu} setSelectedItem={setSelectedItem}
                            />
                        </div>
                    );
                }}
            </FixedSizeGrid>
        )
    }
}

let targetP = {
    _id: "",
    item: {}
}

export function setParentID(id) {
    targetP = id
}


const CDNRoot = (props) => {
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user") || "false") || {});
    const [currentDir, setCurrentDir] = useState("/");
    const [currentParent, setCurrentParentD] = useState(null);
    const [tree, setTree] = useState([]);
    const [upSwitch, setUpSwitch] = useState(false);
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null);
    const gridRef = useRef(null);
    function setCurrentParent(id) {
        if (id === currentParent) return

        let uptreeID

        // if we are going up the tree
        if (tree.length > 0 && id === null) {
            if (tree.length === 1 && currentDir.split("/").length === 3) {
                setCurrentDir("/")
                setCurrentParentD(null)
                refreshFileReferences(null, true);
                setUpSwitch(false)
                setTree([])
                return
            }
            let newTree = [...tree]
            if (newTree.length > 1 && !upSwitch) {
                newTree.pop()
                setUpSwitch(true)
            }
            uptreeID = newTree.pop()
            newTree = newTree.filter(v => v !== null)
            setTree(newTree)
        } else {
            let newTree = [...tree]
            newTree.push(id)
            newTree = newTree.filter(v => v !== null)
            setTree(newTree)
            console.log(newTree)
        }

        if (uptreeID === undefined && id === null) {
            setCurrentParentD(null)
            setUpSwitch(false)
            setCurrentDir("/")
            refreshFileReferences(null, true);
            return
        }

        setLoading(true)
        setFileData([])
        setSelectedItems(null)

        setCurrentParentD(uptreeID || id)
        refreshFileReferences(uptreeID || id, true);
    }
    const [contextMenu, setContextMenu] = useState(null);
    const [fileDatas, setFileData] = useState([]);
    const [cdnAutoConvertOptOut, setCdnAutoConvertOptOut] = useState(null);
    const [lastClick, setLastClick] = useState(0);

    const gridParentRef = useRef(null);
    const [gridWidth, setGridWidth] = useState(100); // Adjust for padding
    const [gridHeight, setGridHeight] = useState(100);
    const [fileModalVisible, setFileModalVisible] = useState(false)
    const [fileModalData, setFileModalData] = useState({})

    useEffect(() => {
        const handleResize = () => {
            if (gridParentRef.current) {
                // Adjust these values as needed for padding or margins
                setGridWidth(gridParentRef.current.clientWidth);
                setGridHeight(gridParentRef.current.clientHeight);
            } else {
                console.log("RECALL!")
                handleResize()
            }
        };

        // Call handleResize initially in case the component mounts with the correct size
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array means this effect runs once on mount and then on unmount

    // Calculate grid dimensions
    const itemWidth = 140;
    const columnCount = Math.floor(gridWidth / itemWidth);
    const colCRef = useRef(columnCount);

    function fileData() {
        if (fileDatas.length === 0) return [];
        const pathParts = currentDir.replace(/\$Ğ!Ğ\$.{6}/g, '').split('/').filter(part => part !== '');
        let parentId = null;

        for (const part of pathParts) {
            const parent = fileDatas.find(file => file.parent === parentId && file.filename.replace(/\$Ğ!Ğ\$.{6}/g, '') === part);
            if (parent) {
                parentId = parent._id;
            } else {
                break;
            }
        }

        parentId = parentId || currentParent || null;

        return fileDatas
    }

    const [newFolderDialog, setNewFolderDialog] = useState(false);
    const [deleteItemDialog, setDeleteItemDialog] = useState(false);
    const [concurrenceDialog, setConcurrenceDialog] = useState(false);
    const [concurrenceInput, setConcurrenceInput] = useState(user.concurrence || 1);
    const [selectedItems, setSelectedItems] = useState(null);
    const [sIW, setSIW] = useState(false);
    const [totalSize, setTotalSize] = useState(0);

    function selectedItem() {
        if (selectedItems?.length === 1) return selectedItems[0];
        return selectedItems;
    }

    function isEncryptedFiletype(filename) {
        return ["png", "jpg", "jpeg", "webp", "gif", "mp4", "webm", "mov", "avi", "mkv", "apng", "flv", "wmv", "m4v"].some(v => filename.includes(v));
    }

    function setSelectedItem(item) {
        if (item) {
            if (!selectedItems) return setSelectedItems([item]);

            if (!selectedItems.includes(item)) {
                setSelectedItems([...selectedItems, item]);
            }
        } else {
            setSelectedItems(null);
        }
    }

    function getSize() {
        let size = 0;
        for (const item of selectedItems) {
            if (item.type === "file")
                size += item.size;
        }
        return size.toFixed(2)
    }

    const [isDragging, setIsDragging] = useState(false);

    function dragStart(val) {
        setIsDragging(val);
    }

    let lrf = 0
    function refreshFileReferences(id, force = false, tID) {
        if (lrf + 1000 > Date.now()) return;
        lrf = Date.now();
        setError(false)
        util.postRequest("/api/content/myFiles", {
            parent: id || force ? id : currentParent,
        }).then((res) => {
            res.data = res.data.map((file, i) => {
                if (document.location.hostname === "localhost" || document.location.hostname === "") {
                    return {
                        ...file,
                        url: `http://localhost:5000/file/${file._id}?k=${file.key}`,
                        thumbnail: `http://localhost:5000/file/${file._id}?k=${file.thumbnail}`,
                        index: i + 1
                    }
                }
                return {
                    ...file,
                    url: `https://ifmw.skyfoster.com/file/${file._id}?k=${file.key}`,
                    thumbnail: `https://ifmw.skyfoster.com/file/${file._id}?k=${file.thumbnail}`,
                    index: i + 1
                }
            })
            setFileData(res.data)
            setLoading(false)
            setUsage(res.usage)
            setAllowance(res.allowance || "∞")
            setCdnAutoConvertOptOut(res.cdnAutoConvertOptOut)
            setTimeout(() => {
                if (tID && gridRef.current) {
                    let itemIndex = res.data.findIndex(f => f._id === tID) || null;

                    if (itemIndex === null) return

                    const columnIndex = itemIndex % (colCRef.current || columnCount || 10);
                    const rowIndex = Math.floor(itemIndex / (colCRef.current || columnCount || 10));

                    gridRef.current.scrollToItem({
                        columnIndex,
                        rowIndex,
                        align: 'center'
                    });

                }
            }, 500);

        })
            .catch((e) => {
                console.error(e)
                setError(true)
            })
    }

    async function handleItemClick(event, item, decryptedData = null) {
        let isDoubleClick = false

        if (selectedItems?.length === 1 && selectedItems[0]._id === item._id && Date.now() - lastClick < 300) {
            isDoubleClick = true
        }

        setLastClick(Date.now())

        if (isDoubleClick) {
            if (props.isSelectDialog && item.type === "file") {
                props.selectItem(item);
                props.close()
                return
            }
            setSelectedItems([item])

            if (item.type === "folder") {
                setCurrentParent(item._id);
                return setCurrentDir(currentDir + item.filename + "/");
            }

            if (item.type === "file") {
                if (decryptedData) return window.open(decryptedData, "_blank");
                if (util.isElectron()) {
                    snackCaller("Downloading File...")
                    let path = await window.ipcRenderer.invoke("downloadFile", {
                        url: item.url,
                        fileName: item.filename
                    })
                    snackCaller("Opening File...")

                    window.ipcRenderer.invoke("openFile", { path: path })
                } else {
                    let previewables = ["image/",
                        //"video/", 
                        "jfif", "heif", "heic", //"mp4", "webm", "mkv"
                    ]
                    if (previewables.some((filt) => item.filename.split("$Ğ!Ğ$")[0].endsWith(filt) || item.mimetype.startsWith(filt))) {
                        setFileModalData(item)
                        setFileModalVisible(true)
                    } else {
                        return window.open(item.url, "_blank");
                    }
                }
            }
        }

        const isShiftPressed = event.shiftKey;
        const isCtrlPressed = event.ctrlKey;

        if (isShiftPressed) {
            if (selectedItems) {
                const firstSelectedItem = selectedItems[0];
                const firstIndex = fileData().indexOf(firstSelectedItem);
                const lastIndex = fileData().indexOf(item);
                const itemsToSelect = fileData().slice(Math.min(firstIndex, lastIndex), Math.max(firstIndex, lastIndex) + 1);
                setSelectedItems(itemsToSelect);
            } else {
                setSelectedItems([item]);
            }
        } else if (isCtrlPressed) {
            if (selectedItems) {
                if (selectedItems.includes(item)) {
                    setSelectedItems(selectedItems.filter((i) => i !== item));
                } else {
                    setSelectedItems([...selectedItems, item]);
                }
            } else {
                setSelectedItems([item]);
            }
        } else {
            //return // FIXME OPTIMIZE
            setSelectedItems([item]);
        }
    }

    if (!user) navigate("/login");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [timesIUsedUnnecessaryThingsBecauseOfReact, setTimesIUsedUnnecessaryThingsBecauseOfReact] = useState(0);
    const [usage, setUsage] = useState(0);
    const [allowance, setAllowance] = useState(0);

    useEffect(() => {
        refreshFileReferences();

        setInterval(() => {
            if (targetP._id !== "") {
                if (targetP?.item?.type === "folder") {
                    setCurrentParent(targetP._id)
                    refreshFileReferences(targetP._id)
                } else {
                    setCurrentParentD(targetP.item.parent)
                    setSelectedItems([targetP.item])
                    refreshFileReferences(targetP.item.parent, false, targetP.item._id)
                }

                targetP._id = ""
            }
        }, 200);

        let esclistener = (event) => {
            if (event.key === "Escape") {
                setSelectedItems(null);
            }
        }

        let ctrlaListener = (event) => {
            if (event.key === "a" && event.ctrlKey) {
                event.preventDefault();

                setSelectedItems(fileData());
            }
        }

        /*let deleteListener = (event) => {
            console.log(selectedItems?.length, selectedItem())
            if (event.key === "Delete" && selectedItem()) {
                setDeleteItemDialog(true);
            }
        }*/

        document.addEventListener("keydown", ctrlaListener);
        //document.addEventListener("keydown", deleteListener);
        document.addEventListener("keydown", esclistener);

        return () => {
            document.removeEventListener("keydown", ctrlaListener);
            //document.removeEventListener("keydown", deleteListener);
            document.removeEventListener("keydown", esclistener);
        }
    }, []);

    const onContextMenuClose = (clear) => {
        setContextMenu(null);
        if (clear) setSelectedItem(null);
    }

    const onDeleteDialogClose = () => {
        setDeleteItemDialog(false);
        setTimeout(() => {
            setSelectedItem(null);
        }, 300);
    }

    const [dragging, setDragging] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);

    const handleDrag = (event) => {
        if (!event.dataTransfer.types.includes('Files')) return;
        event.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDrop = async (event) => {
        //if (!event?.dataTransfer?.types?.includes('Files')) return;
        if (event.preventDefault) event.preventDefault();
        setDragging(false);

        // Handle the dropped files
        let files = event?.dataTransfer?.files || event;

        // Loop through the files
        const CHUNK_SIZE = 20 * 1024 * 1024; // 20MB

        let chunkID = 0;
        let VCID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        async function uploadFile(file) {
            if (fileDatas.find(f => file.name === f.filename?.split("$Ğ!Ğ$")[0])) {
                let exist = fileDatas.find(f => file.name === f.filename?.split("$Ğ!Ğ$")[0]);

                return snackCaller("File already exists, skipping " + exist.filename?.split("$Ğ!Ğ$")[0]);
            }

            let start = 0;
            let chunkID = 0; // Ensure chunkID is defined
            const maxRetries = 10; // Maximum number of retries for each chunk

            while (start < file.size) {
                const chunk = file.slice(start, start + CHUNK_SIZE);
                const formData = new FormData();
                formData.append('file', chunk);
                formData.append('parent', currentParent || null);
                formData.append("isChunked", file.size > CHUNK_SIZE);
                formData.append("isLast", start + CHUNK_SIZE >= file.size);
                formData.append("name", file.name);
                formData.append("chunkID", chunkID++);
                formData.append("vcid", VCID);

                setUploading(true);

                let success = false;
                let attempts = 0;

                while (!success && attempts < maxRetries) {
                    try {
                        await util.postRequest("/api/content/upload", formData, (num) => {
                            let totalChunks = Math.ceil(file.size / CHUNK_SIZE);
                            let currentChunk = Math.floor(start / CHUNK_SIZE);
                            let fileProgress = (currentChunk / totalChunks) * 100;
                            // Assuming setUploadProgress and files are accessible here
                            setUploadProgress(fileProgress);
                        });
                        console.log('Chunk upload complete');
                        success = true;
                    } catch (err) {
                        console.error(err);
                        attempts++;
                        if (attempts >= maxRetries) {
                            console.error(`Failed to upload chunk after ${maxRetries} attempts`);
                            return;
                        }
                    }
                }

                setUploading(false);
                start += CHUNK_SIZE;
            }
        }

        files = Array.from(files)

        let nF = []

        for (let file of files) {
            if (nF[nF.length - 1]?.length >= user.concurrence) {
                nF[nF.length] = [file]
            } else {
                if (!nF[nF?.length - 1 || 0] || nF.length === 0) nF.push([]);
                nF[nF?.length - 1 || 0].push(file)
            }
        }

        for (let batch of nF) {
            let bP = batch.map(f => uploadFile(f))
            await Promise.all(bP)
        }

        refreshFileReferences();
    };

    const [renameDialog, setRenameDialog] = useState(false);
    const [renameFilename, setRenameFilename] = useState("");


    return (
        <Box
            style={{ marginTop: 20, margin: 0 }}
            onDragOver={handleDrag}
            onDragEnter={handleDrag}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <Dialog
                open={fileModalVisible}
                transitionDuration={0}
                onClose={() => {
                    setFileModalVisible(false)
                    setFileModalData({})
                }}
                sx={{
                    backgroundColor: "#00000000"
                }}
                PaperProps={{
                    style: {
                        backgroundColor: "#00000000"
                    }
                }}
                maxWidth
                fullScreen
            >
                <FileViewer
                    setFileModalData={setFileModalData}
                    fileModalData={fileModalData}
                    fileDatas={fileDatas}
                    setFileModalVisible={setFileModalVisible}
                />
            </Dialog>
            <Dialog
                open={renameDialog}
                onClose={() => {
                    setRenameDialog(false)
                    setRenameFilename("")
                }}
            >
                <DialogTitle>Rename {selectedItem()?.type === "folder" ? "Folder" : "File"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>Renaming a file won't change its URL</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        sx={{ marginTop: 2 }}
                        label="New Name"
                        type="text"
                        fullWidth
                        value={renameFilename}
                        onChange={(e) => setRenameFilename(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setRenameDialog(false)
                        setRenameFilename("")
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        util.postRequest("/api/content/rename", { id: selectedItem()._id, name: renameFilename }).then((res) => {
                            refreshFileReferences();
                        })
                        setRenameDialog(false)
                        setRenameFilename("")
                    }}>Rename</Button>
                </DialogActions>
            </Dialog>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    display: dragging ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    backgroundColor: dragging ? "rgba(0, 0, 0, 0.5)" : "transparent",
                    zIndex: dragging ? 100 : -1
                }}
            >
                <Typography
                    sx={{
                        color: "#fff",
                        fontSize: 40,
                        fontFamily: "Russo One",
                        fontWeight: 100,
                    }}
                >
                    Drop Files Here
                </Typography>
            </Box>

            <Snackbar open={uploading}>
                <Box
                    sx={{
                        width: "100%",
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        zIndex: 100
                    }}
                >
                    <LinearProgress variant="determinate" value={uploadProgress} />
                </Box>
            </Snackbar>

            <Dialog
                open={newFolderDialog}
                onClose={() => setNewFolderDialog(false)}
            >
                <DialogTitle>Create New Folder</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Folder Name"
                        type="text"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setNewFolderDialog(false)}>Cancel</Button>
                    <Button onClick={() => {
                        let parent = currentDir === "/" ? null : currentDir.split("/").filter(p => p !== "").pop();
                        parent = fileDatas.find(f => f.filename === parent && f.type === "folder")?._id || null;

                        util.postRequest("/api/content/createFolder", { filename: document.getElementById("name").value, parent: currentParent }).then((res) => {
                            refreshFileReferences();
                        })
                        setNewFolderDialog(false)
                    }}>Create</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={deleteItemDialog}
                onClose={onDeleteDialogClose}
            >
                <DialogTitle>Delete {Array.isArray(selectedItem()) && selectedItem()?.length > 1 ? "Selected Items?" : selectedItem()?.type === "folder" ? "Folder" : "File"}</DialogTitle>
                <DialogContent>
                    {!Array.isArray(selectedItem()) &&
                        <DialogContentText>
                            Are you sure you want to delete {selectedItem()?.type} {selectedItem?.filename}
                        </DialogContentText>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDeleteDialogClose}>Cancel</Button>
                    <Button onClick={() => {
                        util.postRequest("/api/content/delete", { id: Array.isArray(selectedItem()) ? selectedItem().map(i => i._id) : [selectedItem()._id] }).then((res) => {
                            refreshFileReferences();
                        })
                        onDeleteDialogClose()
                    }}>Delete</Button>
                </DialogActions>
            </Dialog>

            <Menu
                open={contextMenu !== null}
                onClose={() => onContextMenuClose(true)}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                {selectedItem()?.type === "file" && <MenuItem onClick={() => {
                    navigator.clipboard.writeText(selectedItem().url)
                    onContextMenuClose()
                    snackCaller("Copied to Clipboard")
                }}>Copy Link</MenuItem>}
                {!Array.isArray(selectedItem()) && <MenuItem onClick={() => {
                    setRenameDialog(true);
                    setRenameFilename(selectedItem().filename.split("$Ğ!Ğ$")[0])
                    onContextMenuClose();
                }}>Rename</MenuItem>}
                {
                    selectedItem()?.parent !== null && currentParent !== null &&
                    <MenuItem onClick={() => {
                        util.postRequest("/api/content/move", { id: selectedItem()._id, parent: "/" }).then((res) => {
                            refreshFileReferences();
                        })
                        onContextMenuClose();
                    }}>Move Up</MenuItem>}
                <MenuItem onClick={() => {
                    setDeleteItemDialog(true);
                    onContextMenuClose();
                }}>Delete</MenuItem>
            </Menu>
            <Box
                sx={{
                    height: "calc(100vh - 70px)",
                    backgroundColor: "#151515",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        height: 40,
                        backgroundColor: "#202020",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <IconButton
                        onClick={() => {
                            const parts = currentDir.split("/");
                            parts.pop();
                            parts.pop();
                            let newDir = parts.join("/") + "/"
                            setCurrentDir(newDir);
                            let curParent = fileDatas.find(f => f.filename === parts.pop() && f.type === "folder")?.parent || null;
                            setCurrentParent(curParent);
                            //refreshFileReferences(fileDatas.find(f => f.filename === parts.pop() && f.type === "folder")?.parent || null);
                        }}
                        disabled={currentDir === "/"}
                    >
                        <ArrowBack />
                    </IconButton>

                    {
                        currentParent !== null && <IconButton
                            onClick={() => {
                                setCurrentDir("/");
                                setCurrentParent(null);
                            }}
                        >
                            <Home />
                        </IconButton>
                    }

                    <Typography
                        sx={{
                            color: "#fff",
                            margin: "auto",
                            marginTop: isDragging && currentDir !== "/" ? 0.3 : 1.7,
                            fontSize: isDragging && currentDir !== "/" ? 26 : 16,
                            lineHeight: isDragging && currentDir !== "/" ? "40px" : "16px",
                            transition: "200ms",
                            fontFamily: "Russo One",
                        }}
                        onDragOver={(event) => {
                            event.preventDefault()
                        }}
                        onDrop={(event) => {
                            if (currentDir === "/") return;
                            event.preventDefault();
                            let parts = currentDir.split("/");
                            parts.pop();
                            parts.pop();
                            let newDir = parts.join("/") + "/"
                            setCurrentDir(newDir);
                            let curParent = fileDatas.find(f => f.filename === parts.pop() && f.type === "folder")?._id || "/";
                            setCurrentParent(curParent);

                            util.postRequest("/api/content/move", { id: event.dataTransfer.getData("text/plain"), parent: currentParent }).then((res) => {
                                refreshFileReferences();
                            })
                        }}
                    >
                        {!isDragging || currentDir === "/" ? `/${user.username}${currentDir.replace(/\$Ğ!Ğ\$.{6}/g, '')}` : "DRAG HERE TO MOVE UP"}
                    </Typography>

                    <IconButton
                        onClick={() => setNewFolderDialog(true)}
                    >
                        <CreateNewFolderOutlined />
                    </IconButton>

                    <IconButton
                        onClick={(event) => {
                            // Create a click event
                            var clickEvent = new MouseEvent("click", {
                                "view": window,
                                "bubbles": true,
                                "cancelable": false
                            });

                            // Create an input element and set its attributes
                            var fileInput = document.createElement("INPUT");
                            fileInput.setAttribute("type", "file");
                            // allow multiple files
                            fileInput.setAttribute("multiple", "multiple");
                            fileInput.style.display = "none"; // Hide the input element

                            // Add an event listener to handle the selected files
                            fileInput.addEventListener("change", (event) => {
                                handleDrop(event.target.files); // Call handleDrop with the selected files
                            });

                            // Append the input element to the body
                            document.body.appendChild(fileInput);

                            // Dispatch the click event on the input element
                            fileInput.dispatchEvent(clickEvent);


                        }}
                    >
                        <UploadFileOutlined />
                    </IconButton>

                    <Dialog
                        open={concurrenceDialog}
                        onClose={() => setConcurrenceDialog(false)}
                    >
                        <DialogTitle>Concurrence Settings</DialogTitle>
                        <DialogContent>
                            <DialogContentText>Concurrence settings allow you to set how many files can be uploaded at once</DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Concurrence"
                                type="number"
                                inputProps={{
                                    min: 1
                                }}
                                fullWidth
                                value={concurrenceInput}
                                onChange={(e) => setConcurrenceInput(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setConcurrenceDialog(false)}>Cancel</Button>
                            <Button onClick={() => {
                                setConcurrenceDialog(false)
                                util.postRequest("/api/auth/setConcurrence", {
                                    concurrence: concurrenceInput
                                }).then((res) => {
                                    snackCaller("Concurrence Updated")
                                }).catch((err) => {
                                    snackCaller(err.message)
                                })
                            }}>Save</Button>
                        </DialogActions>
                    </Dialog>

                    <IconButton
                        onClick={() => setConcurrenceDialog(true)}
                    >
                        <Settings />
                    </IconButton>
                </Box>

                <Box
                    sx={{
                        height: "calc(100vh - 150px)",
                        padding: "18px",
                        paddingTop: 0,
                        overflow: 'hidden',
                    }}
                    ref={gridParentRef}
                >
                    {
                        error ?
                            <Box
                                sx={{
                                    marginTop: "30vh"
                                }}
                            >
                                <Warning
                                    htmlColor={"#cc0000"}
                                    sx={{
                                        fontSize: 50
                                    }}
                                />

                                <Typography
                                    sx={{
                                        color: "#fff",
                                        textAlign: "center"
                                    }}
                                >
                                    Failed to load folder listing.
                                </Typography>
                            </Box>
                            :
                            loading ?
                                <Box
                                    sx={{
                                        marginTop: "30vh"
                                    }}
                                >
                                    <CircularProgress
                                        color="primary"
                                        size={50}
                                    />
                                </Box>
                                :
                                <GridView
                                    columnCount={columnCount}
                                    itemWidth={itemWidth}
                                    gridWidth={gridWidth}
                                    gridHeight={gridHeight}
                                    gridRef={gridRef}
                                    refreshFileReferences={refreshFileReferences}
                                    dragStart={dragStart}
                                    handleItemClick={handleItemClick}
                                    selectedItems={selectedItems}
                                    setContextMenu={setContextMenu}
                                    setSelectedItem={setSelectedItem}
                                    fileData={fileData}
                                />
                    }
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        overflowY: "auto",
                        height: 40,
                        backgroundColor: "#202020",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            paddingLeft: 2,
                            paddingRight: 2,
                            height: 40,
                            backgroundColor: "#303030",
                            position: "relative",
                        }}
                    >
                        <div
                            style={{
                                height: 40,
                                width: `${(usage / allowance) * 100}%`,
                                display: "flex",
                                position: "absolute",
                                backgroundColor: "#454545",
                                left: 0,
                            }}
                        />

                        <Typography
                            sx={{
                                color: "#fff",
                                margin: "auto",
                                zIndex: 1,
                            }}
                        >
                            {usage.toFixed(2)}/{allowance}MB
                        </Typography>
                    </Box>

                    <Typography
                        sx={{
                            color: "#fff",
                            marginLeft: 2
                        }}
                    >
                        {fileData().filter(f => f.type === "file").length} Files, {fileData().filter(f => f.type === "folder").length} Folders
                    </Typography>

                    {(selectedItems?.length || 0 > 0) && <Typography
                        sx={{
                            color: "#fff",
                            marginLeft: 4
                        }}
                    >
                        {selectedItems?.length || 0} Selected Item{selectedItems?.length > 1 ? "s" : ""}
                    </Typography>}

                    {(selectedItems?.length || 0 > 0) && <Typography
                        sx={{
                            color: "#fff",
                            marginLeft: 4
                        }}
                    >
                        {getSize()} MBytes
                    </Typography>}
                </Box>
            </Box>
        </Box >
    );
};

export default CDNRoot;