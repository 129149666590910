import { ErrorOutline, Warning } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { snackCaller } from '../App';
import util from '../util';

function CImage(props) {
    const { esrc, item, ref, style, ...other } = props;
    const [error, setError] = useState(null);
    const [dsrc, setDsrc] = useState(null);
    const [isVideo, setIsVideo] = useState(false)

    React.useEffect(() => {
        if (!esrc && !props.src) {
            return;
        }

        if (dsrc) {
            URL.revokeObjectURL(dsrc);
        }

        setError(false)
        setIsVideo(false)

        const cacheName = 'image-cache';

        async function fetchAndCacheImage(url) {
            const cache = await caches.open(cacheName);
            const cachedResponse = await cache.match(url);

            let videos = ["mp4", "webm","video/"]

            if(item && videos.some((filt) => item.filename.split("$Ğ!Ğ$")[0].endsWith(filt) || item.mimetype?.startsWith(filt))) {
                setIsVideo(true)

                return url
            } else {
                setIsVideo(false)
            }
            

            if (cachedResponse) {
                return cachedResponse;
            }

            setDsrc(null)

            const networkResponse = await fetch(url);
            if (networkResponse.ok) {
                cache.put(url, networkResponse.clone());
                return networkResponse;
            } else {
                throw new Error('Network response was not ok');
            }
        }

        fetchAndCacheImage(props.src).then(async (res) => {
            /*let test = new Image()
            test.src = URL.createObjectURL(await res.blob())

            await new Promise((resolve, reject) => {
                test.onload = resolve;
                test.onerror = reject;
            });*/
            if(typeof res === "string") {
                return setDsrc(res)
            }
            setDsrc(URL.createObjectURL(await res.blob()));
            //test = null
        }).catch((e) => {
            //setIsVideo(true)
            console.log(e)
            setError('Error loading image');
        });
    }, [props.src, esrc]);

    React.useEffect(() => {
        // cleanup

        return () => {
            if (dsrc) {
                URL.revokeObjectURL(dsrc); // free memory, it's not just low level programmers that suffer from memory leaks
            }
        }
    }, [])

    if (!props.src) {
        return (
            <></>
        )
    }

    if (!dsrc && !error) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...props.style
                }}
            >
                <CircularProgress size={40} color="primary" />
            </div>
        )
    }

    if (error) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    fontSize: 50
                }}
            >
                <Warning
                    color="error"
                    fontSize="inherit"
                />
            </div>
        )
    }

    if (isVideo) {
        return (
            <video ref={ref} autoPlay loop muted controls style={style} {...other} src={dsrc} />
        )
    }

    return (
        <img ref={ref} style={style} {...other}  src={dsrc} />
    );
}


export default CImage;