import * as App from './App';
import { useNavigate } from 'react-router-dom';

function isElectron() {
    let isEl = (() => {
        // Renderer process"
        if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
            return true;
        }

        // Main process
        if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
            return true;
        }
    })();

    return isEl;
}

function isDevMode() {
    const isDev = window.NODE_ENV === 'development';
    return isDev;
}

let functionRoot = ""

// Usage
if (isElectron()) {
    console.log('Running inside Electron');
    if (isDevMode()) {
        functionRoot = "http://localhost:5000"
    } else {
        functionRoot = "https://ifmw.skyfoster.com"
    }
} else {
    functionRoot = process.env.REACT_APP_API_BASE_URL
}

function base64urlToArrayBuffer(base64url) {
    const base64 = base64url.replace(/-/g, '+').replace(/_/g, '/');
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

let isConnected = false
let retries = 0


async function awaitConnection() {
    setTimeout(() => {
        if (!isConnected) {
            App.loadingCaller(true)
        }
    }, 3000);
    while (!isConnected) {
        if (retries > 3) {
            if(retries === 4) alert("Gen204 Failed after 3 tries, please try again later.", "Connection Error")
            break
        }
        await new Promise((res) => setTimeout(() => {
            fetch(`${functionRoot}/204`).then((res) => {
                if (res.status === 204) {
                    isConnected = true
                    App.loadingCaller(false)
                    return res()
                }
                retries++
                res()
            }).catch((e) => {
                console.log(`cant gen 204\n\n${e}`)
                retries++
                res()
            })
        }, 1000))
    }
}

export default {
    isElectron: isElectron,
    isAuthenticated: function () {
        return fetch(`${functionRoot}/auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token") || ''
            },
            credentials: 'include'
        }).then((response) => {
            if (response.ok) {
                return true
            } else {
                return false
            }
        }).catch((error) => {
            return false
        })
    },
    postRequest: async function (url, data, progressCallback, contentType = 'application/json') {
        //await awaitConnection()

        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', `${functionRoot}${url}`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem("token") || '');

            if (!(data instanceof FormData)) {
                xhr.setRequestHeader('Content-Type', contentType);
                data = JSON.stringify(data);
            }

            xhr.upload.onprogress = function (event) {
                if (event.lengthComputable) {
                    const percentCompleted = Math.round((event.loaded * 100) / event.total);
                    progressCallback && progressCallback(percentCompleted);
                }
            };

            xhr.onload = function () {
                let response;

                try {
                    response = JSON.parse(this.response);
                } catch (e) {
                    response = {};
                }

                if (this.status === 200) {
                    response.token && localStorage.setItem("token", response.token);
                    response.user && localStorage.setItem("user", JSON.stringify(response.user));

                    if (response.redirect) {
                        App.navigate(response.redirect);
                    }

                    if (response.message) {
                        App.snackCaller(response.message);
                    }

                    resolve(response);
                } else {
                    if (response.redirect) {
                        App.navigate(response.redirect);
                    }

                    if (response.message) {
                        App.snackCaller(response.message);
                    }

                    reject({ message: this.statusText });
                }
            };

            xhr.onerror = function () {
                reject({ message: this.statusText });
            };

            xhr.send(data);
        });
    },
    getRequest: async function (url, cb) {
        //await awaitConnection()

        return fetch(`${functionRoot}${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token") || ''
            },
            credentials: 'include',
        }).then(async (res) => {
            let response
            try { response = await res.json() } catch (e) { response = {} }
            if (res.status === 401) {
                App.snackCaller(response.message || "Unauthorized. Invalid username or password")
                return { code: 401 }
            }
            if (res.status === 500) {
                App.snackCaller("An error occurred. Please try again later.")
                return { code: 500 }
            }

            response.token && localStorage.setItem("token", response.token)
            response.user && localStorage.setItem("user", JSON.stringify(response.user))

            if (response.redirect) {
                App.navigate(response.redirect)
            }

            if (response.message) App.snackCaller(response.message)

            cb && cb(response)

            return response
        }).catch((error) => {
            return { message: error }
        })
    },
    getUserRank: function (rankID) {
        const ranks = {
            0: "Banned",
            1: "User",
            2: "Artist",
            3: "Game Developer",
            4: "Moderator",
            5: "Administrator",
            6: "Owner"
        }

        return ranks[rankID] || "Unknown"
    },
    decrypt: async function (esrc, setError) {
        let key, iv;

        if (localStorage.getItem(esrc)) {
            const stored = JSON.parse(localStorage.getItem(esrc));
            key = base64urlToArrayBuffer(stored.key);
            iv = base64urlToArrayBuffer(stored.iv);
        } else {
            let res = await fetch(`${functionRoot}/content/getEncryptionKey`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ path: esrc })
            })

            res = await res.json();

            if (res.error) {
                setError(res.error);
                return;
            }

            key = base64urlToArrayBuffer(res.key);
            iv = base64urlToArrayBuffer(res.iv);
            localStorage.setItem(esrc, JSON.stringify({ key: res.key, iv: res.iv }));
        }

        const response = await fetch(esrc);
        let buffer = await response.arrayBuffer();

        const cryptoKey = await window.crypto.subtle.importKey(
            'raw',
            key,
            { name: 'AES-CBC', length: 256 },
            false,
            ['decrypt']
        );

        const decrypted = await window.crypto.subtle.decrypt(
            { name: 'AES-CBC', iv: iv },
            cryptoKey,
            buffer
        ).catch((error) => {
            console.error('Decryption error:', error);
        });

        const conversionMap = {
            "png": "image/png",
            "jpg": "image/jpeg",
            "jpeg": "image/jpeg",
            "webp": "image/webp",
            "gif": "image/gif",
            "mp4": "video/mp4",
            "webm": "video/webm",
            "mov": "video/quicktime",
            "avi": "video/x-msvideo",
            "mkv": "video/x-matroska",
            "apng": "image/apng",
            "flv": "video/x-flv",
            "wmv": "video/x-ms-wmv",
            "m4v": "video/x-m4v"
        };

        let ext = esrc.split('.').pop();
        let type = conversionMap[ext] || 'application/octet-stream';

        const blob = new Blob([new Uint8Array(await decrypted)], { type: type });
        const url = URL.createObjectURL(blob);
        return url;
    },
    async handleFileDrop(event, isVisible, parent) {
        event.preventDefault();

        // Handle the dropped file
        const file = event.target.files[0];
        if (!file) return;

        const CHUNK_SIZE = 20 * 1024 * 1024; // 20MB
        let result;
        let start = 0;

        while (start < file.size) {
            const chunk = file.slice(start, start + CHUNK_SIZE);

            const formData = new FormData();
            formData.append('file', chunk);
            formData.append('parent', parent || null);
            formData.append("isChunked", file.size > CHUNK_SIZE);
            formData.append("isLast", start + CHUNK_SIZE >= file.size); // Check if it's the last chunk
            formData.append("name", file.name);

            try {
                result = await this.postRequest("/api/content/upload", formData);
                console.log('Chunk upload complete');
            } catch (err) {
                console.error(err);
                result = { error: err };
            }

            start += CHUNK_SIZE;
        }

        return result;
    },
    Functionify: function (Comp, props) {
        let navigate = useNavigate();
        return <Comp {...props} navigate={navigate} />
    },
}