import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import util from '../util';
import LinearGradient from '../components/LinearGradient';
import { Icon, IconButton, Typography } from '@mui/material';
import Tag from '../components/Tag';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useSwipeable } from 'react-swipeable';

const Home = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user") || "false") || false);
    useEffect(() => {
        if (!user) navigate("/login");
        if (user) navigate("/contentManager");
    }, [])


    return (
        <div
            style={{
                marginTop: 20
            }}
        >

        </div>
    );
};

export default Home;