import { TextField, Typography, Button, Box, Grid, Link } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Util from "../util"
import App from '../App';


const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleLogin = async (event) => {
        event.preventDefault();

        try {
            await Util.postRequest("/api/auth/login", { username, password }).then((res) => {
                if(!res.user) return
                localStorage.setItem("user", JSON.stringify(res.user));
                window.dispatchEvent(new Event('storage'));
            });            
  
        } catch (error) {
            setError('An error occurred during login. Please try again later.');
        }
    };

    return (
        <Box component="form"
            onSubmit={handleLogin}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - 68.5px)'
            }}>
            <Typography variant="h3" component="h1" gutterBottom>
                Login
            </Typography>
            <TextField
                label="Username / Email"
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                margin="normal"
                required
                error={error && error.includes('username')}
                helperText={error && error.includes('username') ? error : ''}
            />
            <TextField
                label="Password"
                variant="outlined"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                required
                error={error && error.includes('password')}
                helperText={error && error.includes('password') ? error : ''}
            />
            <Button variant="contained" color="primary" type="submit">
                Login
            </Button>
            <Typography variant="a" onClick={() => { navigate("/register") }} color={"primary"} sx={{ mt: 2, textDecoration: "underline", cursor: "pointer" }}>
                Don't have an account? Register here
            </Typography>
        </Box>
    );
};

export default Login;