import { useState, useEffect, Fragment } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Autocomplete, Drawer, TextField } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { EditNote, Login, Notifications, Person, RestartAlt } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Util from '../util';

import logo from "../logo192.png"
import { setParentID } from '../pages/CDN';

let pages = []
const settings = pages

function ResponsiveAppBar(props) {
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user') || "false") || {
        username: '',
        avatar: '',
    });
    const [search, setSearch] = useState('')
    const [searchAutoComplete, setSearchAutoComplete] = useState([])

    useEffect(() => {
        Util.postRequest("/api/auth")
            .then((data) => {
                if (data === false) {
                    setUser({
                        username: 'Anonymous',
                        avatar: '',
                        loggedIn: false,
                    });
                    localStorage.clear()
                    window.dispatchEvent(new Event('storage'))
                } else if (data.user) {
                    setUser(data.user);
                } else {
                    setUser({
                        username: 'Anonymous',
                        avatar: '',
                    });
                }
            })

        window.addEventListener("storage", (event) => {
            console.log("!!!")
            if (localStorage.getItem('user')) {
                setUser(JSON.parse(localStorage.getItem('user')));
            } else {
                setUser({
                    username: 'Anonymous',
                    avatar: ''
                });
            }
        })

        return () => {

        };
    }, []);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    const navigate = useNavigate();

    return (
        <div style={{
            //paddingBottom: "1.5rem" 
        }}>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <img
                            src={logo}
                            alt="logo"
                            width="40"
                            height="40"
                            style={{ marginRight: '1rem' }}
                            className="logo-image"
                        />
                        <Typography
                            variant="h6"
                            noWrap
                            onClick={() => navigate('/')}
                            sx={{
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                color: 'inherit',
                                textDecoration: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            IFMW
                        </Typography>

                        <img
                            src={logo}
                            alt="icon"
                            width="40"
                            height="40"
                            className="icon-image"
                        />
                        <Typography
                            variant="h5"
                            noWrap
                            onClick={() => navigate('/')}
                            sx={{
                                ml: 3,
                                paddingRight: 5,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                color: 'inherit',
                                textDecoration: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            IFMW
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Button
                                    key={page}
                                    onClick={() => navigate('/' + page.toLowerCase())}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>

                        <Autocomplete
                            id="search"
                            freeSolo
                            value={search}
                            options={searchAutoComplete}
                            getOptionLabel={(option) => option.filename || ""}
                            onChange={(e, value) => {
                                console.log(value)
                                setSearch("")
                                setSearchAutoComplete([])
                                setParentID({
                                    _id: value._id,
                                    item: value
                                })
                            }}
                            renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                    <TextField
                                        {...params.inputProps}
                                        placeholder="Search"
                                        value={search}
                                    
                                        onChange={(e) => {
                                            setSearch(e.target.value)

                                            if(e.target.value.length < 1) return; 
                                            Util.postRequest("/api/content/search", { query: e.target.value }).then((res) => {
                                                setSearchAutoComplete(res.data)
                                                console.log(res.data)
                                            })
                                        }}

                                        variant="standard"
                                        sx={{ width: "30vw", borderRadius: "5px" }}
                                    />
                                </div>
                            )}
                        />


                        <Box sx={{ flexGrow: 0, marginLeft: "3rem" }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar sx={{ backgroundColor: user.avatar ? "#00000000" : stringToColor(user.username) }} alt={() => user.username} src={user.avatar}>{user.username.slice(0, 1)}</Avatar>
                                </IconButton>
                            </Tooltip>

                            <Fragment>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    keepMounted
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&::before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuItem onClick={user.username !== "Anonymous" ? () => { handleCloseUserMenu() } : () => { navigate('/login'); handleCloseUserMenu() }}>
                                        <Avatar sx={{ backgroundColor: user.avatar ? "#00000000" : stringToColor(user.username) }} alt={() => user.username} src={user.avatar}>{user.username.slice(0, 1)}</Avatar> {user.username}
                                    </MenuItem>

                                    <Divider />

                                    {user.username !== "Anonymous" ? // if user is logged in
                                        <div>
                                            {user.role === "admin" ? // if user is admin
                                                <MenuItem onClick={() => {
                                                    handleCloseUserMenu()
                                                    Util.getRequest("/api/restart")
                                                }}>
                                                    <ListItemIcon>
                                                        <RestartAlt fontSize="small" />
                                                    </ListItemIcon>
                                                    Reboot Server
                                                </MenuItem>
                                                : <></>}

                                            <MenuItem onClick={() => {
                                                Util.getRequest("/api/auth/logout")
                                                localStorage.removeItem('user');
                                                window.dispatchEvent(new Event('storage'))
                                            }}>
                                                <ListItemIcon>
                                                    <Logout fontSize="small" />
                                                </ListItemIcon>
                                                Logout
                                            </MenuItem>
                                        </div>

                                        : // if user is not logged in

                                        <div>
                                            <MenuItem onClick={() => navigate('/login')}>
                                                <ListItemIcon>
                                                    <Login fontSize="small" />
                                                </ListItemIcon>
                                                Login
                                            </MenuItem>

                                            <MenuItem onClick={() => navigate('/register')}>
                                                <ListItemIcon>
                                                    <PersonAdd fontSize="small" />
                                                </ListItemIcon>
                                                Register
                                            </MenuItem>
                                        </div>
                                    }
                                </Menu>
                            </Fragment>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>

            <Drawer
                anchor="left"
                open={drawerVisible}
                onClose={() => setDrawerVisible(false)}
                sx={{ display: { xs: 'block', md: 'none' } }}
            >
                <div
                    style={{
                        width: 250,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {settings.map((setting) => (
                        <MenuItem key={setting} onClick={() => setDrawerVisible(false)}>
                            <Typography textAlign="center">{setting}</Typography>
                        </MenuItem>
                    ))}
                </div>

            </Drawer>
        </div>

    );
}
export default ResponsiveAppBar;
