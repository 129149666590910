import { ArrowBack, ArrowForward, Close, Download, ErrorOutline, Warning } from '@mui/icons-material';
import { Box, ButtonBase, CircularProgress, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { snackCaller } from '../App';
import util from '../util';
import Image from './Image';

function FileViewer(props) {
    let {
        fileModalData,
        setFileModalData,
        fileDatas,
        setFileModalVisible
    } = props

    const fileModalDataRef = useRef(fileModalData)

    const previewables = ["image/",
        //"video/", 
        "jfif", "avif", "heic", //"mp4", "webm", "mkv"
    ]

    const [zoomLevel, setZoomLevel] = useState(1);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [translateX, setTranslateX] = useState(0);
    const [translateY, setTranslateY] = useState(0);

    const handleBackButt = (FMD) => {
        // get the previous file or wraparound
        let currentIndex = fileDatas.indexOf(fileDatas.find(f => f._id === FMD._id))
        let newFileIndex = currentIndex - 1 < 0 ? fileDatas.length - 1 : currentIndex - 1
        let item = fileDatas[newFileIndex]

        while (!previewables.some((filt) => item.filename.split("$Ğ!Ğ$")[0].endsWith(filt) || item.mimetype?.startsWith(filt))) {
            if (fileDatas[newFileIndex - 1]) {
                item = fileDatas[newFileIndex - 1]
                newFileIndex--
            } else {
                item = fileDatas[fileDatas.length - 1]
                newFileIndex = fileDatas.length - 1
            }
        }

        setFileModalData(item)
        setZoomLevel(1);
        setTranslateX(0);
        setTranslateY(0);
    }

    const handleForwardButt = (FMD) => {
        // get the next file or wraparound
        let currentIndex = fileDatas.indexOf(fileDatas.find(f => f._id === FMD._id))
        let newFileIndex = currentIndex + 1 < fileDatas.length - 1 ? currentIndex + 1 : 0
        let item = fileDatas[newFileIndex]

        while (!previewables.some((filt) => item.filename.split("$Ğ!Ğ$")[0].endsWith(filt) || item.mimetype?.startsWith(filt))) {
            if (fileDatas[newFileIndex + 1]) {
                item = fileDatas[newFileIndex + 1]
                newFileIndex++
            } else {
                item = fileDatas[0]
                newFileIndex = 0
            }
        }

        setFileModalData(item)
        setZoomLevel(1);
        setTranslateX(0);
        setTranslateY(0);
    }

    const handleWheelZoom = (event) => {
        const delta = Math.sign(event.deltaY);
        let newZoomLevel = zoomLevel - delta * 0.1;
        newZoomLevel = Math.max(1, Math.min(5, newZoomLevel)); // Clamp zoom between 1 and 5
        setZoomLevel(newZoomLevel);
    };

    const handleDoubleClickZoom = () => {
        if (zoomLevel === 1) {
            setZoomLevel(2);
        } else {
            setZoomLevel(1);
            setTranslateX(0);
            setTranslateY(0);
        }
    };

    const handleMouseDown = (event) => {
        if (zoomLevel > 1) {
            setIsDragging(true);
            setStartX(event.clientX - translateX);
            setStartY(event.clientY - translateY);
        }
    };

    const handleMouseMove = (event) => {
        if (isDragging) {
            setTranslateX(event.clientX - startX);
            setTranslateY(event.clientY - startY);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        let arrowListener = (ev) => {
            try {
                if (ev.key === "ArrowLeft") { // GO BACK
                    document.getElementById("bbfm").click()
                } else if (ev.key === "ArrowRight") { // GO FORWARD
                    document.getElementById("fbfm").click()
                }
            } catch (e) {

            }
        }

        window.addEventListener("keydown", arrowListener)

        return () => {
            window.removeEventListener("keydown", arrowListener)
        }
    }, [])

    if (!fileModalData._id) {
        return <></> // return prematurely cause no data lol
    }

    if (!fileDatas) {
        return <></> // return prematurely cause no file datas
    }

    let file = fileDatas.find(f => f._id === fileModalData._id)

    if (!file) {
        return <></> // return prematurely cause can't find file
    }

    return (
        <Box
            sx={{
                width: "100vw",
                height: "100vh",
                textAlign: "center",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#000"
            }}
        >
            <Typography sx={{
                position: "absolute",
                zIndex: 3,
                backdropFilter: "blur(5px) brightness(0.7)",
                alignSelf: "center",
                fontSize: "1vw",
            }} variant="h5">
                {file.filename.split("$Ğ!Ğ$")[0]}
            </Typography>

            <Box
                sx={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    zIndex: 5
                }}
            >
                <IconButton
                    onClick={() => {
                        window.open(fileModalData.url, "_blank");
                    }}
                >
                    <Download />
                </IconButton>

                <IconButton
                    onClick={() => {
                        setFileModalVisible(false)
                        setFileModalData({})
                    }}
                >
                    <Close />
                </IconButton>
            </Box>

            <Box
                sx={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    zIndex: 2,
                    pointerEvents: "none"
                }}
            >
                <ButtonBase
                    id="bbfm"
                    sx={{
                        position: "absolute",
                        width: "65px",
                        height: "100%",
                        left: 0,
                        pointerEvents: "all"
                    }}

                    onClick={() => handleBackButt(fileModalData)}
                >
                    <ArrowBack />
                </ButtonBase>

                <ButtonBase
                    id="fbfm"
                    sx={{
                        position: "absolute",
                        width: "65px",
                        height: "100%",
                        right: 0,
                        pointerEvents: "all"
                    }}
                    onClick={() => handleForwardButt(fileModalData)}
                >
                    <ArrowForward />
                </ButtonBase>
            </Box>

            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    zIndex: 1,
                    top: 0,
                    display: "flex",
                    justifyContent: "center",
                    overflow: "hidden" // Add overflow hidden to prevent image from spilling out
                }}
                onWheel={handleWheelZoom}
                onMouseDown={handleMouseDown} // Add mouse down event listener
                onMouseMove={handleMouseMove} // Add mouse move event listener
                onMouseUp={handleMouseUp}   // Add mouse up event listener
                onMouseLeave={handleMouseUp} // Add mouse leave event listener
            >
                <Image
                    style={{
                        width: `${zoomLevel * 100}%`,
                        height: `${zoomLevel * 100}%`,
                        objectFit: "contain",
                        transformOrigin: "center",
                        transition: "transform 50ms ease", // Add smooth transition for zoom
                        transform: `translate(${translateX}px, ${translateY}px)`, // Add translate transform
                    }}
                    onDragStart={(ev) => ev.preventDefault()}
                    src={fileModalData.url}
                    item={fileModalData}
                    onDoubleClick={handleDoubleClickZoom} // Add double click event listener
                />
            </Box>
        </Box>
    )
}


export default FileViewer;