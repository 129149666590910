import { Typography, Box } from '@mui/material';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CDNRoot from './CDN/index';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const Home = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user") || "false") || false);
    if(!user) navigate("/login");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box style={{ marginTop: 20, margin: 0,  }}>
            <CDNRoot />
        </Box>
    );
};

export default Home;