import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, MemoryRouter, HashRouter } from "react-router-dom";
import Index from './pages/index';
import Login from './pages/login';
import Register from './pages/register';
import ContentManager from "./pages/contentManager";
import AppBar from './components/AppBar';
import { ThemeProvider } from '@emotion/react';
import { CircularProgress, Container, Snackbar, Typography, createTheme, Button } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useNavigate } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import util from './util';

// Supports weights 100-900
import '@fontsource-variable/montserrat/wght.css';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#9f3aeb',
    },
    secondary: {
      main: '#a6e3f9',
    },
    background: {
      default: '#121212',
      paper: '#212121',
    },
  },
});

export let snackCaller = () => { }
export let loadingCaller = () => { }
export let navigate = () => { }
export let callGenericModal = () => { }
export let hideModal = () => { }

function MNav() {
  const nav = useNavigate();
  navigate = (path) => {
    nav(path)
  }
  return (
    <></>
  )
}

const GenericModal = ({ open, close, title, content, options }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent
        >
          {typeof content === "string" ?
            <DialogContentText id="alert-dialog-description">
              {content}
            </DialogContentText>
            :
            content
          }
        </DialogContent>
        <DialogActions>
          {options.map((option, index) => (
            <Button
              color={option.color || "primary"}
              key={index}
              onClick={() => {
                if (option.onClick) option.onClick(close)
              }}
            >
              {option.text}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </div>
  );

}

function App() {
  const [snackVisible, setSnackVisible] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState();
  const [loaderVisible, setLoaderVisible] = React.useState(false);
  const [reportData, setReportData] = React.useState({})
  const [genericModalVisible, setGenericModalVisible] = React.useState(false)
  const [genericModalData, setGenericModalData] = React.useState({ title: "", content: "", options: [] })

  snackCaller = (message, children) => {
    setSnackMessage(message)
    setSnackVisible(true)
  }

  loadingCaller = () => {
    setLoaderVisible(!loaderVisible)
  }

  callGenericModal = (title, content, options) => {
    setGenericModalVisible(true)
    setGenericModalData({ title, content, options })
  }

  hideModal = () => {
    setGenericModalVisible(false)
  }

  return (
    <div className="App">
      <ThemeProvider
        theme={theme}
      >
        <GenericModal open={genericModalVisible} close={() => {
          setGenericModalVisible(false);
          setTimeout(() => {
            setGenericModalData({ title: "", content: "", options: [] })
          }, 100);
        }} title={genericModalData.title} content={genericModalData.content} options={genericModalData.options} />
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            pointerEvents: loaderVisible ? "all" : "none",
            opacity: loaderVisible ? 1 : 0,
            zIndex: 1000,
            backdropFilter: "blur(5px)",
            transition: "0.5s",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              marginRight: 30,
            }}
          >
            Please Wait...
          </Typography>

          <CircularProgress
            color="primary"
          />
        </div>
        <CssBaseline />
        <HashRouter >
          <AppBar />
          <MNav />
          <Snackbar open={snackVisible} autoHideDuration={6000} onClose={() => setSnackVisible(false)} message={snackMessage} />

          <Container
            maxWidth="xl"
          >
            <Routes>
              <Route path="/" element={<Index />} />
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="contentManager" element={<ContentManager />} />
              <Route path="*" element={<Index />} />
            </Routes>
          </Container>
        </HashRouter>
      </ThemeProvider>
    </div >
  );
}

export default App;
